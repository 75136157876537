import createSocket from 'socket.io-client'
import { SOCKET_INIT, SOCKET_DESTROY, USERS_CONNECTED } from 'src/actions'

const baseUrl =
  process.env.NODE_ENV !== 'production'
    ? 'http://localhost:8000'
    : 'https://stage.newton.finance'

const initialState = {
  socket: null,
  connectedUsers: []
}

const socketReducer = (state = initialState, action) => {
  switch (action.type) {
    case SOCKET_INIT: {
      const socket = createSocket(baseUrl)

      return {
        ...state,
        socket
      }
    }

    case SOCKET_DESTROY: {
      state.socket.removeAllListeners()
      state.socket.disconnect()

      return {
        ...state,
        socket: null
      }
    }

    case USERS_CONNECTED: {
      return {
        ...state,
        connectedUsers: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export default socketReducer
